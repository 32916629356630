a {
  text-decoration: none;
  color: inherit;
}

.navbar-nav {
  color: white;
  // center everything
  display: flex;
  justify-content: center;
  align-items: center;
  // make it responsive
}
