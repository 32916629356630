.cagri-listesi {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 20px;
  padding: 0 20px;
  grid-auto-rows: auto;

  .cagri-card {
    min-height: fit-content;
    text-align: left;
    padding: 10;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;

    .cagri-details {
      font-size: 0.8rem;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .cagri-button {
      a {
        text-decoration: none;
        color: inherit;
      }
      // button styling
      padding: 10px 20px;
      margin: 0 10px;
      border: 1px solid #eaeaea;
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      color: #000;
      font-size: 0.8rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: rgb(0, 17, 169);
        color: white;
      }
    }
  }

  @media (max-width: 767px) {
    .cagri-listesi {
      padding: 0 5px;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
}
