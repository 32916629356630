.nav-item {
  margin: 0 1rem;
}

footer {
  background-color: #212529;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 10;

  a {
    text-decoration: none;
    font-size: 1.2rem;
    color: #9b9d9e;

    &:hover {
      font-weight: bold;
      color: white;
    }
  }
}
