// .logo-card {
//   height: 300px;
//   width: 300px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 10px;
// }

.image-card {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: 90%;
  height: 90%;
  margin: 10px;
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  //   on hover animation
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  }
}
