.main-content {
  padding: 0.5rem 10rem 2rem 10rem;
  max-width: 1680px;
  margin: 0 auto;
  min-height: 85vh;
  /* center the content */
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  .auth {
    display: flex;
    min-height: 50vh;
  }
}

@media (max-width: 768px) {
  .main-content {
    padding: 2rem 0rem;
  }
}
