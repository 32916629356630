.btn {
  padding: 15px 50px;
  margin: 10px;
  font-size: 20px;
  background-color: rgb(0, 68, 226);
  color: white;
  border: none;
  border-radius: 50px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    background-color: rgb(0, 17, 169);
    color: white;
  }
}

.ana-sayfa-tuslar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ana-sayfa {
  display: flex;
  flex-direction: column;
  min-height: 85vh;
}

.iletisim-notu {
  // put this div to bottom of the page
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;

  .left {
    float: left;
    padding: 0 2rem;
  }
  .right {
    float: right;
    padding: 0 2rem;
  }
}
