.konum-link {
  color: blue;
  text-decoration: none;
  border-radius: 0.5rem;
  &:hover {
    color: #8b0000;
  }
}

.cagri-detay {
  // align text to left
  padding-top: 20px;
  text-align: left;
}

.cagri-harita-liste-tuslar {
  // align buttons in a row
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .geri-tusu {
    margin: 0 10px 0 0;
    text-align: left;
    border: 1px solid #797979;
    border-radius: 5px;
    padding: 10px 10px 0 10px;
    max-width: fit-content;

    // on hover, change background color
    &:hover {
      background-color: rgb(0, 17, 169);
      color: white;
    }
  }
}
