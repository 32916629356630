// scss container form and input for registeration form

.form-container-register {
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  //   automatically fill the height of the parent element
  flex: 1;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.input {
  width: 300px;
  height: 40px;
  margin: 10px 0;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

// button for registeration form
.button {
  width: 300px;
  height: 40px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 16px;
  cursor: pointer;
}

.login p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
