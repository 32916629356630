.form-container {
  justify-content: center;
  align-items: center;
  // background-color: #dc0d0d;
  display: flex;
  //   automatically fill the height of the parent element
  flex: 1;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem;
  // border-radius: 0.5rem;
  // box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 1%;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

label {
  width: 75px;
  margin-right: 10px;
  font-weight: bold;
}
.input {
  width: 300px;
  height: 40px;
  margin: 10px 0;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

// button for registeration form
.button {
  width: 300px;
  height: 40px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 16px;
  cursor: pointer;
}

// button on hover effect
.button:hover {
  background-color: #ffc218c4;
}

// button on click effect
.button:active {
  background-color: rgba(255, 143, 24, 0.77);
}

.adres-doldur {
  width: 300px;
  height: 40px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f6da23;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.adres-doldur:hover {
  transform: scale(1.02);
  background-color: #f67723;
}

.adres-doldur:active {
  // background-color: rgba(255, 143, 24, 0.77);
}

// small p tag for registeration form
.register p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.register a {
  color: blue;
  text-decoration: none;
}

.textarea {
  height: 200px;
}
