.kullanici-yorumu {
  margin-bottom: 20px;
  border: 1px solid #797979;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  .username {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
  .date {
    font-size: 12px;
    text-align: right;
  }
  .comment {
    margin: 10px;
    border: 1px solid #e6e6e6;
    font-size: 14px;
    padding: 20px;
    text-align: left;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
  }
}

.kullanici-yorumu-kendisi {
  margin-bottom: 20px;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  padding: 10px;
  background-color: #dfdfdf;
  .username {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #8b0000;
  }
  .date {
    font-size: 12px;
    text-align: right;
  }
  .comment {
    margin: 10px;
    border: 1px solid #e6e6e6;
    font-size: 14px;
    padding: 20px;
    text-align: left;
  }
}
.link-login {
  color: #8b0000;
  text-decoration: none;
  &:hover {
    color: #8b0000;
    // cursor
    cursor: pointer;
  }
}
