.row {
  margin-top: 20px;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5px;
}

.form-note {
  margin-top: -10px;
  font-size: 10px;
  color: rgb(137, 0, 0);
}

.card-header {
  font-weight: bold;
  font-size: 18px;
}

.card-text {
  font-size: 14px;
}

.card-button {
  background-color: rgb(166, 111, 111);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
}

.cagri-card-button {
  background-color: rgb(166, 111, 111);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
}

.filtre-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid rgb(166, 111, 111);
  border-radius: 5px;
}

@media (max-width: 768px) {
  .cagri-table {
    font-size: 14px;
    // prevent table to be overflowed
    overflow-x: auto;
    // make table responsive
    display: block;

    // hide the overflowed part
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .cagrilarim-table {
    // fit the table to the screen
    font-size: 6px;

    // prevent table to be overflowed
    // overflow-x: auto;
    // make table responsive
    display: block;

    // hide the overflowed part
    &::-webkit-scrollbar {
      display: none;
    }

    .cagri-card-button {
      font-size: 8px;
    }
  }
  .filtre-box {
    font-size: 12px;
  }
}
